<template>
  <div class="form-content">
    <el-form ref="pageRef" label-width="80px">
      <div class="form-section">
        <div class="section-title">基础设置</div>
        <div class="section-area flex-section-area">
          <div class="cover">
            <el-image style="width: 100%; height: 100%" :src="$store.state.panoramaInfo.img" fit="img"></el-image>
            <div class="edit-area">
              <btn-upload btnName="选择封面" url="" filed="''" :max-num="1" @checked-files="imgCheckedFiles"></btn-upload>
              <div class="tips">建议尺寸 512 * 512</div>
            </div>
          </div>
          <div class="cover-right">
            <el-form-item label="项目标题">
              <el-input clearable v-model="$store.state.panoramaInfo.title" placeholder="请输入项目标题" @input="itemChange('title', $event)"></el-input>
            </el-form-item>
            <el-form-item label="项目描述">
              <el-input clearable type="textarea" :autosize="{ minRows: 4, maxRows: 4}" placeholder="请输入项目描述" v-model="$store.state.panoramaInfo.desc" @input="itemChange('desc', $event)"></el-input>
            </el-form-item>
          </div>
        </div>
      </div>
      <div class="form-section">
        <div class="section-title">全局设置</div>
        <div class="section-area">
          <el-button type="primary" size="small" @click="setDefultCamera">设置初始镜头</el-button>
          <el-button type="primary" size="small" @click="customLogoVisible = true">自定义LOGO</el-button>
          <el-button type="primary" size="small" @click="IntroductionVisible = true">项目简介</el-button>
          <el-button type="primary" size="small" @click="bgMusicVisible = true">项目背景音乐</el-button>
        </div>
      </div>
      <div class="form-section">
        <div class="section-title">全局开关</div>
        <div class="section-area flex-section-area">
          <div class="check-item">
            <div class="title">浏览量</div>
            <div class="check-btn">
              <el-switch v-model="$store.state.panoramaInfo.isViews" active-color="#13ce66" inactive-color="#ff4949" :active-value="2" :inactive-value="1" @change="itemChange('isViews', $event)"></el-switch>
            </div>
          </div>
          <div class="check-item">
            <div class="title">开启留言</div>
            <div class="check-btn">
              <el-switch v-model="$store.state.panoramaInfo.isMessage" active-color="#13ce66" inactive-color="#ff4949" :active-value="2" :inactive-value="1" @change="itemChange('isMessage', $event)"></el-switch>
            </div>
          </div>
          <div class="check-item">
            <div class="title">显示留言</div>
            <div class="check-btn">
              <el-switch v-model="$store.state.panoramaInfo.isViewMessage" active-color="#13ce66" inactive-color="#ff4949" :active-value="2" :inactive-value="1" @change="itemChange('isViewMessage', $event)"></el-switch>
            </div>
          </div>
          <div class="check-item">
            <div class="title">点赞</div>
            <div class="check-btn">
              <el-switch v-model="$store.state.panoramaInfo.isAgree" :active-value="2" :inactive-value="1" active-color="#13ce66" inactive-color="#ff4949" @change="itemChange('isAgree', $event)"></el-switch>
            </div>
          </div>
          <div class="check-item">
            <div class="title">分享</div>
            <div class="check-btn">
              <el-switch v-model="$store.state.panoramaInfo.isShare" :active-value="2" :inactive-value="1" active-color="#13ce66" inactive-color="#ff4949" @change="itemChange('isShare', $event)"></el-switch>
            </div>
          </div>
          <div class="check-item">
            <div class="title">全屏</div>
            <div class="check-btn">
              <el-switch v-model="$store.state.panoramaInfo.isFullScreen" :active-value="2" :inactive-value="1" active-color="#13ce66" inactive-color="#ff4949" @change="itemChange('isFullScreen', $event)"></el-switch>
            </div>
          </div>
          <div class="check-item">
            <div class="title">手机陀螺仪</div>
            <div class="check-btn">
              <el-switch v-model="$store.state.panoramaInfo.isGyroscope" active-color="#13ce66" inactive-color="#ff4949" :active-value="2" :inactive-value="1" @change="itemChange('isGyroscope', $event)"></el-switch>
            </div>
          </div>
          <div class="check-item">
            <div class="title">VR眼镜</div>
            <div class="check-btn">
              <el-switch v-model="$store.state.panoramaInfo.isVr" :active-value="2" :inactive-value="1" active-color="#13ce66" inactive-color="#ff4949" @change="itemChange('isVr', $event)"></el-switch>
            </div>
          </div>
        </div>
      </div>
      <div class="form-section">
        <div class="section-title">微信分享设置</div>
        <div class="section-area">
          <el-form-item label="分享图标">
            <upload :url="$store.state.panoramaInfo.wxImg" :filed="'wxImg'" :max-num="1" @checked-files="checkedShareImg" @del-select="delShareImg"></upload>
          </el-form-item>
          <el-form-item label="分享标题">
            <el-input v-model="$store.state.panoramaInfo.wxTitle" @input="itemChange('wxTitle', $event)"></el-input>
          </el-form-item>
          <el-form-item label="分享描述">
            <el-input type="textarea" placeholder="请输入分享描述内容" v-model="$store.state.panoramaInfo.wxDesc" maxlength="30" show-word-limit @input="itemChange('wxDesc', $event)"></el-input>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <!-- 设置初始镜头弹窗 -->
    <el-dialog title="设置初始镜头" :visible.sync="InitialShotVisible" width="400px" :before-close="InitialShotClose">
      <div class="carmera-list">
        <div :class="[item.is_default === 1 ? 'active' : '', 'item']" v-for="item in cameraList" :index="item.id" :key="item.id" @click="changeDefaultCamera(item)">
          <div class="camera-left">
            <el-image style="width: 60px; height: 60px" :src="item.img" fit="cover"></el-image>
            <span>{{item.title}}</span>
          </div>
          <el-image class="checked-icon" v-if="item.is_default === 1" :src="require('../../../../assets/img/checked.png')"></el-image>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="InitialShotVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 设置自定义logo -->
    <el-dialog title="设置自定义logo" :visible.sync="customLogoVisible" width="400px" :before-close="customLogoClose">
      <div class="set-item">
        <div class="title">是否显示LOGO</div>
        <div class="item-content">
          <el-tooltip :content="($store.state.panoramaInfo.isLogo === 1) ? '当前状态：不显示' : '当前状态：显示'" placement="top">
            <el-switch v-model="$store.state.panoramaInfo.isLogo" active-color="#13ce66" inactive-color="#ff4949" :active-value="2" :inactive-value="1" @change="itemChange('isLogo', $event)"></el-switch>
          </el-tooltip>
        </div>
      </div>
      <div class="set-item">
        <div class="title">上传Logo文件</div>
        <div class="item-content">
          <upload :url="$store.state.panoramaInfo.logo" :filed="'logo'" :max-num="1" @checked-files="checkedLogo" @del-select="delLogo"></upload>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="customLogoVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 设置项目简介弹窗 -->
    <el-dialog title="项目简介" :visible.sync="IntroductionVisible" width="400px" :before-close="IntroductionClose">
      <div class="set-item">
        <div class="title">是否开启项目简介功能</div>
        <div class="item-content">
          <el-tooltip :content="($store.state.panoramaInfo.isIntroduction === 1) ? '当前状态：关闭' : '当前状态：开启'" placement="top">
            <el-switch v-model="$store.state.panoramaInfo.isIntroduction" active-color="#13ce66" inactive-color="#ff4949" :active-value="2" :inactive-value="1" @change="itemChange('isIntroduction', $event)"></el-switch>
          </el-tooltip>
        </div>
      </div>
      <div class="set-item">
        <div class="title">填写项目简介内容</div>
        <div class="item-content">
          <QuillBar v-model="$store.state.panoramaInfo.introduction" :contenttext="$store.state.panoramaInfo.introduction" @inputChange="itemChange('introduction', $event)"></QuillBar>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="IntroductionVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 设置项目背景音乐 -->
    <el-dialog title="设置背景音乐" :visible.sync="bgMusicVisible" width="400px" :before-close="bgMusicClose">
      <div class="set-item">
        <div class="title">是否开启背景音乐</div>
        <div class="item-content">
          <el-tooltip :content="($store.state.panoramaInfo.isBgMusic === 1) ? '当前状态：关闭' : '当前状态：开启'" placement="top">
            <el-switch v-model="$store.state.panoramaInfo.isBgMusic" active-color="#13ce66" inactive-color="#ff4949" :active-value="2" :inactive-value="1" @change="itemChange('isBgMusic', $event)"></el-switch>
          </el-tooltip>
        </div>
      </div>
      <div class="set-item">
        <div class="title">上传背景音乐</div>
        <div class="item-content">
          <upload :url="$store.state.panoramaInfo.bgMusic" :filed="'bgMusic'" :max-num="1" @checked-files="checkedBgMusic" @del-select="delBgMusic"></upload>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="bgMusicVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import BtnUpload from '../../../common/BtnUpload'
import Upload from '@/components/common/Upload'
import QuillBar from '../../../common/QuillBar'
export default {
  name: 'BaseSet',
  components: {
    BtnUpload,
    Upload,
    QuillBar
  },
  data () {
    return {
      // 设置初始镜头
      InitialShotVisible: false,
      cameraList: [],
      // 设置自定义logo
      customLogoVisible: false,
      // 项目简介
      IntroductionVisible: false,
      // 背景音乐
      bgMusicVisible: false,
      // quill设置
      quill: null,
      editorOption: {
        placeholder: '请输入内容',
        theme: 'snow',
        modules: {}
      }
    }
  },
  methods: {
    // input变化事件
    itemChange (name, val) {
      this.$store.commit('updatePanoramaItem', { name: name, val: val })
    },
    // 重新选择缩略图
    imgCheckedFiles (data) {
      this.$store.commit('updatePanoramaItem', { name: 'img', val: data.url })
    },
    // 设置初始镜头
    setDefultCamera () {
      // 将父组件传值的场景列表解析children里的镜头，组成新的镜头数组
      const cameraList = []
      this.$store.state.sceneList.map((item) => {
        item.children.map((item2) => {
          cameraList.push(item2)
        })
      })
      this.cameraList = cameraList
      this.InitialShotVisible = true
    },
    // 项目简介
    setIntroduction () {},
    // 项目背景音乐
    setBgMusicVisible () {},
    // 微信分享图片上传组件返回值
    checkedShareImg (data) {
      this.$store.commit('updatePanoramaItem', { name: 'wxImg', val: data.url })
    },
    // 删除微信分享图片已选择文件
    delShareImg (field, item, pattern) {
      // 单文件删除
      this.$store.commit('updatePanoramaItem', { name: 'wxImg', val: '' })
    },
    // 关闭设置初始镜头弹窗
    InitialShotClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    // 更换项目默认镜头
    changeDefaultCamera (item) {
      // 判断被点击的镜头是否已经是默认镜头
      if (item.is_default === 1) {
        // 已经是默认镜头,请求后端取消该镜头默认
        this.setDefaultCamera(item.id, 2)
      } else {
        // 不是默认镜头，请求后端增加该镜头默认
        this.setDefaultCamera(item.id, 1)
      }
    },
    // 添加默认镜头请求
    async setDefaultCamera (id, status) {
      const { data: res } = await this.$http.post('/panorama-change-default-camera', { id: id, status: status, mid: this.$route.params.project_id })
      if (res.status === 200) {
        // 更新父组件信息
        await this.getProjectInfo()
        this.$message.success(res.msg)
        this.InitialShotVisible = false
      } else {
        this.$message.error(res.msg)
      }
    },
    // 关闭设置自定义logo弹窗
    customLogoClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    // 上传自定义logo
    checkedLogo (data) {
      this.$store.commit('updatePanoramaItem', { name: 'logo', val: data.url })
    },
    // 删除logo
    delLogo (field, item, pattern) {
      this.$store.commit('updatePanoramaItem', { name: 'logo', val: '' })
    },
    // 关闭项目简介
    IntroductionClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    // 关闭背景音乐
    bgMusicClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    // 上传背景音乐
    checkedBgMusic (data) {
      this.$store.commit('updatePanoramaItem', { name: 'bgMusic', val: data.url })
    },
    // 删除背景音乐
    delBgMusic (field, item, pattern) {
      this.$store.commit('updatePanoramaItem', { name: 'bgMusic', val: '' })
    },
    // 获取项目信息
    async getProjectInfo () {
      const { data: res } = await this.$http.get('/panorama-project', { params: { mid: this.$route.params.project_id } })
      if (res.status === 200) {
        this.$store.commit('updatePanoramaAll', res.data.projectInfo)
        this.$store.commit('updateSceneList', res.data.sceneList)
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>

<style scoped>
.form-content{
  width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
}
.form-section{
  margin-top: 20px;
}
.section-title{
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 600;
  margin-bottom: 20px;
}
.section-area{}
.flex-section-area{
  display: flex;
  flex-wrap: wrap;
}
.cover{
  width: 160px;
  height: 160px;
  position: relative;
}
.cover .el-image img{
  border-radius: 6px;
}
.edit-area{
  position: absolute;
  bottom: 10px;
  right: 10px;
  text-align: right;
}
.cover-right{
  flex: 1;
  margin-left: 24px;
}
.tips{
  font-size: 12px;
  margin-top: 4px;
  color: #FFFFFF;
}
.check-item{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  margin-bottom: 20px
}
.check-item .title{
  color: #bbbec4;
  font-size: 13px;
}
.check-item .check-btn{
  margin-left: 10px;
}
/deep/.el-form-item label{
  color: #bbbec4;
  font-size: 13px;
  text-align: left;
}
.carmera-list{}
.carmera-list .item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  cursor: pointer;
  margin-bottom: 10px;
}
.carmera-list .item .camera-left{
  display: flex;
  align-items: center;
  justify-content: center;
}
.carmera-list .item .camera-left span{
  margin-left: 10px;
  color: rgba(255,255,255,0.8);
}
.carmera-list .item,.active{
  background-color: rgba(255,255,255,0.2);
  border-radius: 4px;
}
.carmera-list .item .checked-icon{
  width: 30px;
  height: 30px;
}
/deep/.el-dialog__body{
  background-color: #1c2438;
}
.set-item{
  margin-bottom: 20px;
}
.set-item .title{
  margin-bottom: 6px;
}
.set-item /deep/.ql-container{
  min-height: 200px;
}
</style>
